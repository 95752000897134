<template>
  <tr>
    <td class="text-md-center">{{ index + 1 }}</td>
    <td v-if="item">
      <span>{{ item.product.name }}</span>
    </td>
    <td v-if="item.product.laboratory">
      <span>{{ item.product.laboratory.name }}</span>
    </td>
    <td v-else>
      <span></span>
    </td>
    <td>
      <v-text-field
        v-model="item.lot"
        outlined
        dense
        class="mt-6"
      ></v-text-field>
    </td>
    <td>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="item.expiration_date"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="item.expiration_date"
            readonly
            outlined
            dense
            class="mt-6"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="item.expiration_date"
          type="month"
          no-title
          scrollable
          color="green lighten-1"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="success"
            @click="menu = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            text
            color="success"
            @click="$refs.menu.save(item.expiration_date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </td>

    <td v-if="item.product.box_quantity > 1">
      <span v-if="!editable">{{ item.quantity }}</span>
      <v-text-field
        v-else
        v-model="item.quantity"
        outlined
        dense
        class="mt-6"
        v-uppercase
        @change="changeQuantity(item.quantity)"
        @input="up"
        ref="qua"
      ></v-text-field>
    </td>
    <td v-else>
      <span v-if="!editable">{{ item.quantity }}</span>
      <v-text-field
        v-else
        v-model="item.quantity"
        outlined
        dense
        class="mt-6"
        @input="changeQuantity(item.quantity)"
        type="number"
        min="1"
        step="1"
        ref="qua"
      ></v-text-field>
    </td>
    <td v-if="item">
      <span>{{ item.buy_unit }}</span>
    </td>
    <td>
      <span>{{ item.total | currency }}</span>
    </td>
    <td class="text-md-center">
      <v-icon
        small
        class="mr-2"
        @click="$emit('removeList', item)"
      >
        {{ icons.mdiCloseThick }}
      </v-icon>
      <v-icon
        small
        class="mr-2"
        @click="$emit('saveList', item)"
      >
        {{ icons.mdiContentSave }}
      </v-icon>
    </td>
  </tr>
</template>
<script>
import {mdiAccount, mdiDelete, mdiPencil, mdiCloseThick, mdiContentSave} from '@mdi/js'

export default {
  name: "EditRowPurchase",
  props: ['item', 'index'],
  data() {
    return {
      icons: {
        mdiAccount,
        mdiPencil,
        mdiDelete,
        mdiCloseThick,
        mdiContentSave
      },
      editable: false,
      menu: false,
      modal: false,
    }
  },
  computed: {
    saleUnit: {
      get() {
        return this.item ? this.item.sale_unit : 0.0
      },
      set(value) {
        this.item.sale_unit = value
      }
    },
    quantity: {
      get() {
        return this.item.product.quantity
      },
      set(value) {
        this.item.quantity = value
      }
    },
    total: {
      get() {
        return this.item.total
      },
      set(value) {
        this.item.total = value
      }
    }
  },
  watch: {
    total() {
      this.$emit('total')
    },
    quantity() {
      this.changeQuantity(this.item.quantity)
    }
  },
  mounted() {
    this.toggleEditable()
  },
  methods: {
    toggleEditable() {
      this.editable = !this.editable;
    },
    changeQuantity(val) {
      if (val) {
        if (this.item.product.box_quantity > 1) {
          let valFirst = 0
          let valLast = 0

          const values = val.split("F")
          for (let i = 0; i < values.length; i++) {
            valFirst = values[0]
            valLast = values[1]
          }

          if (valLast) {
            this.item.product_quantity = (parseFloat(valFirst) * parseFloat(this.item.product.box_quantity) + parseFloat(valLast)).toFixed(2)
            this.item.total = (parseFloat(this.item.product_quantity) * parseFloat(this.item.product.buy_unit)).toFixed(2)
            this.totalPartial = this.item.total
          }
        } else {
          this.item.total = (parseFloat(this.item.product.buy_unit) * parseFloat(val)).toFixed(2)
          this.totalPartial = this.item.total
        }
      } else {
        this.item.total = 0.00
      }
    },


    up() {
      if (parseInt(this.item.quantity) > -1 && !this.item.quantity.includes('F')) {
        this.$nextTick(() => {
          this.item.quantity = parseInt(this.item.quantity) + 'F1'
        })
      }
    },
  }
}
</script>
<style scoped>
.basil {
  background-color: #FFFBE6 !important;
}

.basil--text {
  color: #356859 !important;
}

.inputs-container,
.col-12 {
  padding-top: 0;
  padding-bottom: 0;
}

.theme--light.v-input {
  padding-top: 0;
}

.v-input__slot {
  margin-bottom: 0;
}
</style>
