<template>
  <div>
    <v-simple-table class="elevation-1">
      <template v-slot:default>
        <thead>
        <tr style="background: #4caf50">
          <th class="text-left" style="color: white">
            NRO
          </th>
          <th class="text-left" style="color: white">
            PRODUCTO
          </th>
          <th class="text-left" style="color: white">
            LABORATORIO
          </th>
          <th class="text-left" style="color: white">
            LOTE
          </th>
          <th class="text-left" style="color: white">
            VENCIMIENTO
          </th>
          <th class="text-left" style="color: white">
            CANTIDAD
          </th>
          <th class="text-left" style="color: white">
            PRECIO UNITARIO
          </th>
          <th class="text-left" style="color: white">
            TOTAL
          </th>
          <th class="text-left" style="color: white">
            ACCIONES
          </th>
        </tr>
        </thead>
        <tbody>
        <EditRowPurchase
          @removeList="removeList($event)"
          @dataRow="dataRow($event)"
          @total="dataTotal"
          @saveList="saveList($event)"
          :item="item"
          :index="index"
          v-for="(item, index) in purchases.details"
          :key="index"
        />
        </tbody>
      </template>
    </v-simple-table>
    <v-footer color="grey lighten-5">
      <v-row>
        <v-col md="11" class="text-center justify-center">
          <h4 class="font-weight-medium">
            SUBTOTAL
          </h4>
        </v-col>
        <v-col md="1" class="text-center justify-center">
          <h4 class="font-weight-medium">
            {{ subtotal | currency }}
          </h4>
        </v-col>
        <v-col md="11" class="text-center justify-center">
          <h4 class="font-weight-medium">
            TOTAL
          </h4>
        </v-col>
        <v-col md="1" class="text-center justify-center">
          <h4 class="font-weight-medium">
            {{ total | currency }}
          </h4>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>
<script>
import EditRowPurchase from "../rows/EditRowPurchase";

export default {
  name: "TableEditPurchase",
  components: {EditRowPurchase},
  props: ['purchases'],
  data() {
    return {
      subtotal: this.purchases.total,
      total: this.purchases.total,
      increment: 0.0,
      decrement: 0.0,
      snackbar: false,
    }
  },
  computed: {
    igv: {
      get() {
        return this.editedItem.igv
      },
      set(value) {
        this.editedItem.igv = value
      }
    },
    details: {
      get() {
        return this.purchases.details
      },

    }
  },
  watch: {
    /*igv () {
      if (this.editedItem.igv === 1) {
        this.increment = this.total * 0.18
        this.total = this.total + this.increment
      }else {
        this.total = this.total - this.increment
      }
    },*/
    resetPurchases() {
      this.reset()
    },
    details() {
      this.dataTotal()
    },
  },
  methods: {
    removeList ($event) {
      this.$emit('remove', $event, this.purchases.number)
    },
    saveList ($event) {
      this.$emit('saveList', $event, parseFloat(this.total).toFixed(2), parseFloat(this.subtotal).toFixed(2))
    },
    dataRow ($event) {
      const indexItem = this.dataPurchase.findIndex(x => x.id === $event.id)
      if (indexItem >= 0) {
        return ''
      } else {
        this.dataPurchase.push($event)
        this.$emit('listProducts', this.dataPurchase)
      }
    },
    dataTotal () {
      let quantity = 0.0
      if (this.purchases.details.length) {
        this.purchases.details.forEach(item => {
          quantity += parseFloat(item.total)
        })
      }
      this.total = quantity
      this.subtotal = quantity
      this.purchases.subtotal = quantity
      this.purchases.total = quantity
    },
    reset () {
      this.subtotal = 0.0
      this.total = 0.0
      this.increment = 0.0
      this.decrement = 0.0
      this.totalRow = []
      this.dataPurchase = []
    }
  }
}
</script>
<style scoped>
.basil {
  background-color: #FFFBE6 !important;
}

.basil--text {
  color: #356859 !important;
}

.inputs-container,
.col-12 {
  padding-top: 0;
  padding-bottom: 0;
}

.theme--light.v-input {
  padding-top: 0;
}

.v-input__slot {
  margin-bottom: 0;
}
</style>
